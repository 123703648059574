import QrCodeDisplay from "./QrCodeDisplay";
import { useState } from "react";

function LandingPage() {
    const [question, setQuestion] = useState(null);

    const onQuestionClick = (index) => {
        console.log(index);
        if (question === index) {
            setQuestion(null);
        } else {
            setQuestion(index);
        }
    }
    return (
        <div>
            <div className="flex justify-around items-center md:mb-44 mb-36">
                <div className="text-center max-w-4xl">
                    <div>

                        <h1 className="text-3xl font-semibold mb-10 lg:text-5xl text-center">Create free, dynamic QR codes with advanced customization and analytics</h1>
                        <p className="">Change destinations anytime, customize every detail, set device-specific redirects, and secure your codes with passwords. Gain valuable insights with our powerful analytics dashboard. All features are completely free.

                        </p>
                    </div>
                    <div className="mt-5">
                        <a href="https://app.FastQR.link" className="text-xl text-white bg-indigo-400 px-6 py-3 rounded-xl   mb-1">Get Started</a>
                    </div>
                </div>
            </div>

            <div className=" md:mb-44 mb-36">
                <h2 className="mb-20 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 text-center">Want a static QR code?</h2>
                <QrCodeDisplay />
            </div>

            <section className="bg-white mx-auto md:mb-44 mb-36">
                <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                    <div className="max-w-screen-md mb-8 lg:mb-16 mx-auto text-center">
                        <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 ">Features</h2>
                    </div>
                    <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">

                        <div>
                            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>

                            </div>
                            <h3 className="mb-2 text-xl font-bold ">Dynamic QR Codes</h3>
                            <p className="text-gray-500 ">Change the destination of your QR code anytime without altering the code itself. Flexibility at your fingertips.</p>
                        </div>



                        <div>
                            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z" />
                                </svg>

                            </div>
                            <h3 className="mb-2 text-xl font-bold ">Fully Customizable QR Codes</h3>
                            <p className="text-gray-500 ">Personalize every aspect of your QR code: adjust colors, modify eye shapes, change patterns, and choose a background color or make it transparent for seamless integration with your design.</p>
                        </div>
                        <div>
                            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                </svg>

                            </div>
                            <h3 className="mb-2 text-xl font-bold ">iOS/Android Redirect</h3>
                            <p className="text-gray-500 ">Direct users to different destinations based on their device type. Tailor the user experience for iOS and Android effortlessly.</p>
                        </div>
                        <div>
                            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
                                </svg>

                            </div>
                            <h3 className="mb-2 text-xl font-bold ">Advanced Analytics</h3>
                            <p className="text-gray-500 ">Track the location, time, device, and browser of every scan. Powerful analytics made simple.</p>
                        </div>
                        <div>
                            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
                                </svg>

                            </div>
                            <h3 className="mb-2 text-xl font-bold ">Password Protection</h3>
                            <p className="text-gray-500 ">Secure your QR codes with a password to prevent unauthorized access. Ensure only your intended audience can access your content.</p>
                        </div>
                        <div>
                            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>

                            </div>
                            <h3 className="mb-2 text-xl font-bold ">Completely Free</h3>
                            <p className="text-gray-500 ">Enjoy all these features at no cost. Create, customize, and manage your dynamic QR codes without any fees. Unlock the full potential of QR codes for free.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="grow basis-1 space-y-3">
                <div className="max-w-screen-md mb-8 lg:mb-16 mx-auto text-center">
                    <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 ">FAQ</h2>
                </div>
                <div className="collapse collapse-arrow border border-black">
                    <input type="radio" name="my-accordion-3" onClick={() => onQuestionClick(0)} checked={question === 0} />
                    <div className="collapse-title text-xl font-medium">
                        Is it really free?
                    </div>
                    <div className="collapse-content">
                        <p>
                            Absolutely! Our service is completely free and always will be.

                        </p>
                    </div>
                </div>
                <div className="collapse collapse-arrow border border-black">
                    <input type="radio" name="my-accordion-3" onClick={() => onQuestionClick(1)} checked={question === 1} />
                    <div className="collapse-title text-xl font-medium">
                        Can I change the destination whenever I want?


                    </div>
                    <div className="collapse-content">
                        <p>Yes, you can update the destination as many times as you like.
                        </p>
                    </div>
                </div>

                <div className="collapse collapse-arrow border border-black">
                    <input type="radio" name="my-accordion-3" onClick={() => onQuestionClick(2)} checked={question === 2} />
                    <div className="collapse-title text-xl font-medium">
                        How many QR codes can I create?
                    </div>
                    <div className="collapse-content">
                        <p>You can maintain up to 5 active QR codes at any given time. If you reach the limit, you can delete some and create new ones.
                        </p>
                    </div>
                </div>

                <div className="collapse collapse-arrow border border-black">
                    <input type="radio" name="my-accordion-3" onClick={() => onQuestionClick(3)} checked={question === 3} />
                    <div className="collapse-title text-xl font-medium">
                        What kind of metrics can I track?


                    </div>
                    <div className="collapse-content">
                        <p>You can track the country, device, browser, date, and operating system of each scan.
                        </p>
                    </div>
                </div>


                <div className="collapse collapse-arrow border border-black">
                    <input type="radio" name="my-accordion-3" onClick={() => onQuestionClick(4)} checked={question === 4} />
                    <div className="collapse-title text-xl font-medium">
                        Do you support unlimited scans?




                    </div>
                    <div className="collapse-content">
                        <p>Yes, we offer unlimited scans, and your QR codes will always function. However, we can only track up to 1000 scans per month per QR code. If you exceed this limit, the scans will still be redirected, but they won’t appear in the analytics dashboard. Additionally, we retain analytics data for 3 months.

                        </p>
                    </div>
                </div>


                <div className="collapse collapse-arrow border border-black">
                    <input type="radio" name="my-accordion-3" onClick={() => onQuestionClick(5)} checked={question === 5} />
                    <div className="collapse-title text-xl font-medium">
                        How can you support a free plan forever?



                    </div>
                    <div className="collapse-content">
                        <p>Our application is highly optimized and doesn’t cost much to run, allowing us to provide it for free indefinitely.

                        </p>
                    </div>
                </div>

            </div >
        </div>
    )
}

export default LandingPage;