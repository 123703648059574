function Terms() {
    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md mt-10">
            <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
            <p className="text-gray-600 mb-6">Effective Date: April 09, 2024</p>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Agreement to Terms</h2>
                <p className="text-gray-700">By using our services, you agree to be bound by these Terms. If you do not agree, do not use the services.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Privacy Notice</h2>
                <p className="text-gray-700">Our Privacy Policy outlines the collection, use, and disclosure of data and information by FastQR in connection with our site. The Privacy Policy, as updated from time to time, is incorporated into these Terms, and by using our services, you agree to the data practices described therein.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Changes to These Terms or the Services</h2>
                <p className="text-gray-700">We reserve the right to update these Terms at our sole discretion. If we make changes, we will notify you by posting the updated Terms on our site and/or through other communications.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Content Guidelines</h2>
                <p className="text-gray-700">You are prohibited from using our site to generate QR codes that link to websites involved in illegal activities such as pornography, trafficking, hacking, and gambling. Additionally, our service cannot be used for industries related to music, wine, banking, or any product related to pork.</p>
                <p className="text-gray-700">While you retain ownership of your content, FastQR reserves the right, at our sole discretion, to refuse to host any content. This may occur due to a violation of these Terms or any applicable laws and regulations. Failure to comply with these rules may result in the deletion and prohibition of any non-compliant data.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Prohibited Uses</h2>
                <p className="text-gray-700">We do not allow the use of our service in the following industries:</p>
                <ul className="list-disc list-inside text-gray-700">
                    <li>Music</li>
                    <li>Banking</li>
                    <li>Wine</li>
                    <li>Gambling</li>
                    <li>Any edible product related to pork</li>
                </ul>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Account Responsibilities</h2>
                <p className="text-gray-700">You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device. You agree to accept responsibility for all activities that occur under your account.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Termination of Service</h2>
                <p className="text-gray-700">We reserve the right to terminate or suspend your access to our services without prior notice or liability for any reason, including if you breach these Terms.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Limitation of Liability</h2>
                <p className="text-gray-700">To the fullest extent permitted by applicable law, FastQR will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the service; (b) any unauthorized access to or use of our servers and/or any personal information stored therein.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Indemnification</h2>
                <p className="text-gray-700">You agree to defend, indemnify, and hold harmless FastQR, its affiliates, and their respective officers, directors, employees, and agents, from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Service or your violation of these Terms.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Governing Law and Dispute Resolution</h2>
                <p className="text-gray-700">These Terms are governed by and construed in accordance with the laws of [insert jurisdiction], without regard to its conflict of law principles. Any disputes arising out of or relating to these Terms or the Service will be resolved through binding arbitration in [insert location], as the exclusive remedy for such disputes.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Miscellaneous</h2>
                <p className="text-gray-700"><strong>Severability:</strong> If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</p>
                <p className="text-gray-700"><strong>Entire Agreement:</strong> These Terms constitute the entire agreement between you and FastQR regarding the use of the Service.</p>
            </div>

        </div>
    );
}

export default Terms;