import logo from "./logo.svg";
import { Outlet, Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
function App() {

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)

  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const onMessageChange = (e) => {
    setMessage(e.target.value)
  }

  const handleCreate = async (e) => {
    e.preventDefault()
    const data = {
      email: email,
      message: message
    }
    try {
      await axios.post('https://backend.FastQR.link/contact/', data)
      setShowSuccess(true)

    } catch (error) {
      console.log("error")
    }

  }

  const handleCloseCreationForm = (e) => {
    e.preventDefault()
    setEmail('')
    setMessage('')
    const modal = document.getElementById('contact_modal')
    modal.close()
  }

  return (

    <div className="xl:max-w-7xl  lg:max-w-4xl sm:mx-auto pt-10 md:max-w-xl max-w-sm  mx-2">

      <nav className="flex justify-around mt-20 items-center md:mb-44 mb-36">
        <Link to="/" className="hover:underline me-4 md:me-6">
          <div className="flex gap-2 justify-center items-center ">

            <img src={logo} alt="FastQR" />
            <h1 className="md:text-xl font-bold text-gray-800  text-sm">FastQR</h1>
          </div>
        </Link>

        <div>
          <a href="https://app.FastQR.link" className="md:text-xl  text-white bg-indigo-400 md:px-6 px-4 md:py-3 py-2 rounded-xl text-sm">Get Started</a>
        </div>
      </nav>


      <dialog id="contact_modal" className="modal" onCancel={handleCloseCreationForm}>
        <div className="modal-box max-w-2xl  ">
          <h3 className="font-bold text-lg mb-5">Get in touch</h3>

          {showSuccess &&
            <div>

              <div role="alert" className="alert alert-success">
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>Your message was sent successfully. We will get back to you soon!</span>
              </div>
              <div className=" mx-auto mt-3 text-center">

                <button className="btn" onClick={handleCloseCreationForm}>Cancel</button>
              </div>
            </div>
          }

          {!showSuccess &&
            <div >

              <p className="text-gray-500">We would love to hear from you. Please fill out the form below and we will get back to you as soon as possible.</p>
              <form className=" space-y-3 " onSubmit={handleCreate} >
                <div className="mt-10">

                  <label>

                    Email
                  </label>
                  <input type="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={onEmailChange} value={email} required />
                </div>
                <div className="mt-10">

                  <label >
                    Message
                  </label>
                  <textarea id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 " onChange={onMessageChange} value={message} placeholder="Write your thoughts here..."></textarea>
                </div>

                <div className="flex justify-around mt-6">

                  <button className="btn  bg-sky-600 text-white px-6" onSubmit={handleCreate}>Send</button>

                  <button className="btn" onClick={handleCloseCreationForm}>Cancel</button>

                </div>
              </form>

            </div>
          }
        </div>
      </dialog>
      <div id="detail">
        <Outlet />
      </div>


      <footer className="bg-white rounded-lg shadow mt-8 mb-8 ">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
          <span className="text-sm text-gray-500 sm:text-center ">© 2024 <a href="https://FastQR.link" className="hover:underline">FastQR</a>. All Rights Reserved.
          </span>
          <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500  sm:mt-0">
            <li>
              <Link to="/terms" className="hover:underline me-4 md:me-6">Terms</Link>
            </li>
            <li>
              <Link to="/privacy" className="hover:underline me-4 md:me-6">Privacy Policy</Link>
            </li>
            <li>
              <button className="hover:underline me-4 md:me-6" onClick={() => { document.getElementById('contact_modal').showModal() }}>Contact</button>
            </li>

          </ul>
        </div>
      </footer>
    </div >

  );
}

export default App;
