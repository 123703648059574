import React, { useState, useEffect, useRef } from "react";
import QRCodeStyling from "qr-code-styling";


function QrCodeDisplay() {

    const [url, setUrl] = useState("");
    const [margin, setmargin] = useState(0);
    const [backgroundColor, setbackgroundColor] = useState('#ffffff');
    const [backgroundColorType, setBackgroundColorType] = useState('colored');
    const [patternType, setpatternType] = useState('extra-rounded');
    const [patternColorType, setpatternColorType] = useState('single');
    const [patternColor1, setpatternColor1] = useState('#000000');
    const [patternColor2, setpatternColor2] = useState('#000000');
    const [outerEyePattern, setouterEyePattern] = useState('extra-rounded');
    const [outerEyeColorType, setouterEyeColorType] = useState('single');
    const [outerEyeColor1, setouterEyeColor1] = useState('#000000');
    const [outerEyeColor2, setouterEyeColor2] = useState('#000000');
    const [innerEyePattern, setinnerEyePattern] = useState('dot');
    const [innerEyeColorType, setinnerEyeColorType] = useState('single');
    const [innerEyeColor1, setinnerEyeColor1] = useState('#000000');
    const [innerEyeColor2, setinnerEyeColor2] = useState('#000000');

    const [LogoMargin, setLogoMargin] = useState(0);
    const [logo, setLogo] = useState(null);

    const [downloadSize, setDonwloadSize] = useState(1);
    const [downloadExt, setDonwloadExt] = useState('png');
    const [qrCodeDownload, setQrCodeDownload] = useState(null);

    const ref = useRef();
    const qrCode = useRef();

    const [question, setQuestion] = useState(null);

    const onQuestionClick = (index) => {
        if (question === index) {
            setQuestion(null);
        } else {
            setQuestion(index);
        }
    }

    const onLogoMarginChange = (event) => {
        setLogoMargin(event.target.value);
    }

    const onLogoChange = (event) => {
        setLogo(event.target.files[0]);
    }


    const sizes = {
        0: 256,
        1: 512,
        2: 1024,
        3: 2048,
        4: 2 * 2048
    }

    const onDownloadSizeChange = (event) => {
        setDonwloadSize(event.target.value)
    }

    const onDownloadExtChange = (event) => {
        setDonwloadExt(event.target.value)
    }

    const downloadQR = () => {
        qrCodeDownload.download({ name: "qr", extension: downloadExt });
    }
    console.log("render")
    useEffect(() => {
        const initialOptions = {
            width: 300,
            height: 300,
            image: logo ? URL.createObjectURL(logo) : null,
            type: 'canvas',
            data: url === '' ? 'https://FastQR.link' : url,
            margin: margin,
            dotsOptions: {
                color: patternColor1,
                type: patternType,
            },
            backgroundOptions: {
                color: backgroundColor,
            },
            cornersSquareOptions: {
                color: outerEyeColor1,
                type: outerEyePattern,
            },
            cornersDotOptions: {
                color: innerEyeColor1,
                type: innerEyePattern,
            },
            imageOptions: {
                crossOrigin: "anonymous",
                margin: LogoMargin
            }
        };

        if (patternColorType === 'gradient') {
            initialOptions.dotsOptions.gradient = {
                type: 'linear', // 'radial'
                rotation: 0,
                colorStops: [{ offset: 0, color: patternColor1 }, { offset: 1, color: patternColor2 }]
            };
        }

        if (innerEyeColorType === 'gradient') {
            initialOptions.cornersDotOptions.gradient = {
                type: 'linear', // 'radial'
                rotation: 0,
                colorStops: [{ offset: 0, color: innerEyeColor1 }, { offset: 1, color: innerEyeColor2 }]
            };
        }

        if (outerEyeColorType === 'gradient') {
            initialOptions.cornersSquareOptions.gradient = {
                type: 'linear', // 'radial'
                rotation: 0,
                colorStops: [{ offset: 0, color: outerEyeColor1 }, { offset: 1, color: outerEyeColor2 }]
            };
        }

        qrCode.current = new QRCodeStyling(initialOptions);
        qrCode.current.append(ref.current);
        setQrCodeDownload(new QRCodeStyling({ ...initialOptions, width: sizes[downloadSize], height: sizes[downloadSize], type: downloadExt }));
        return () => {
            qrCode.current = null;
        };
    }, []);

    useEffect(() => {
        if (qrCode.current) {

            const initialOptions = {
                width: 300,
                height: 300,
                image: logo ? URL.createObjectURL(logo) : null,
                type: 'canvas',
                data: url === '' ? 'https://FastQR.link' : url,
                margin: margin,
                dotsOptions: {
                    color: patternColor1,
                    type: patternType,
                },
                backgroundOptions: {
                    color: backgroundColor,
                },
                cornersSquareOptions: {
                    color: outerEyeColor1,
                    type: outerEyePattern,
                },
                cornersDotOptions: {
                    color: innerEyeColor1,
                    type: innerEyePattern,
                },
                imageOptions: {
                    crossOrigin: "anonymous",
                    margin: LogoMargin
                }
            };

            if (patternColorType === 'gradient') {
                initialOptions.dotsOptions.gradient = {
                    type: 'linear', // 'radial'
                    rotation: 0,
                    colorStops: [{ offset: 0, color: patternColor1 }, { offset: 1, color: patternColor2 }]
                };
            }

            if (innerEyeColorType === 'gradient') {
                initialOptions.cornersDotOptions.gradient = {
                    type: 'linear', // 'radial'
                    rotation: 0,
                    colorStops: [{ offset: 0, color: innerEyeColor1 }, { offset: 1, color: innerEyeColor2 }]
                };
            }

            if (outerEyeColorType === 'gradient') {
                initialOptions.cornersSquareOptions.gradient = {
                    type: 'linear', // 'radial'
                    rotation: 0,
                    colorStops: [{ offset: 0, color: outerEyeColor1 }, { offset: 1, color: outerEyeColor2 }]
                };
            }
            // destroy the previous instance
            qrCode.current = new QRCodeStyling(initialOptions);
            ref.current.innerHTML = '';
            qrCode.current.append(ref.current);
            setQrCodeDownload(new QRCodeStyling({ ...initialOptions, width: sizes[downloadSize], height: sizes[downloadSize], type: downloadExt }));
        }
    }, [
        url,
        margin,
        patternType,
        patternColorType,
        patternColor1,
        patternColor2,
        outerEyePattern,
        outerEyeColorType,
        outerEyeColor1,
        outerEyeColor2,
        innerEyePattern,
        innerEyeColorType,
        innerEyeColor1,
        innerEyeColor2,
        backgroundColor,
        downloadSize,
        downloadExt,
        LogoMargin,
        logo
    ]);

    const onUrlChange = (event) => {

        setUrl(event.target.value);
    }


    const onMarginChange = (event) => {
        setmargin(event.target.value);
    }

    const onBackgroundColorChange = (event) => {
        setbackgroundColor(event.target.value);
    }
    const onBackgroundColorTypeChange = (event) => {
        setBackgroundColorType(event.target.value);
        if (event.target.value === 'transparent') {
            setbackgroundColor("transparent");
        } else {
            setbackgroundColor("#FFFFFF");
        }
    }

    const onPatternTypeChange = (event) => {
        setpatternType(event.target.value);

    }

    const onPatternColorTypeChange = (event) => {
        setpatternColorType(event.target.value);

    }

    const onPatternColor1Change = (event) => {
        setpatternColor1(event.target.value);
    }

    const onPatternColor2Change = (event) => {
        setpatternColor2(event.target.value);
    }

    const onOuterEyeTypeChange = (event) => {
        setouterEyePattern(event.target.value);
    }

    const onOuterEyeColorTypeChange = (event) => {
        setouterEyeColorType(event.target.value);
    }

    const onOuterEyeColor1Change = (event) => {
        setouterEyeColor1(event.target.value);
    }

    const onOuterEyeColor2Change = (event) => {
        setouterEyeColor2(event.target.value);
    }


    const onInnerEyeTypeChange = (event) => {
        setinnerEyePattern(event.target.value);
    }

    const onInnerEyeColorTypeChange = (event) => {
        setinnerEyeColorType(event.target.value);
    }

    const onInnerEyeColor1Change = (event) => {
        setinnerEyeColor1(event.target.value);
    }

    const onInnerEyeColor2Change = (event) => {
        setinnerEyeColor2(event.target.value);
    }


    return (
        <div className=" lg:flex justify-around items-center ">



            <div className="lg:w-96">
                <div className="mb-4">

                    <label className="input input-bordered flex items-center gap-2 mb-2  ">
                        URL
                        <input type="url" className="grow " value={url} onChange={onUrlChange} />
                    </label>

                    <label className="input input-bordered flex items-center gap-2  ">
                        Margin
                        <input type="number" className="grow " min={0} max={100} value={margin} onChange={onMarginChange} />
                    </label>
                </div>



                <div className="space-y-6 ">

                    <div className="collapse collapse-arrow border">
                        <input type="radio" name={`my-accordion-`} onClick={() => onQuestionClick(0)} checked={question === 0} />
                        <div className="collapse-title text-xl font-medium">
                            Background options
                        </div>
                        <div className="collapse-content">

                            <div>
                                <div className="flex gap-5">
                                    <div className="form-control ">
                                        <label className="label cursor-pointer flex gap-2 ">

                                            <span className="label-text">Transparent</span>
                                            {
                                                backgroundColorType === 'transparent' && <input type="radio" name={`backgroundColorType`} className="radio " value="transparent" checked onChange={onBackgroundColorTypeChange} />
                                            }
                                            {
                                                backgroundColorType !== 'transparent' && <input type="radio" name={`backgroundColorType`} className="radio " value="transparent" onChange={onBackgroundColorTypeChange} />
                                            }
                                        </label>
                                    </div>
                                    <div className="form-control">
                                        <label className="label cursor-pointer flex gap-2">
                                            <span className="label-text">Colored</span>
                                            {
                                                backgroundColorType === 'colored' && <input type="radio" name={`backgroundColorType`} className="radio " value="colored" checked onChange={onBackgroundColorTypeChange} />
                                            }
                                            {
                                                backgroundColorType !== 'colored' && <input type="radio" name={`backgroundColorType`} className="radio " value="colored" onChange={onBackgroundColorTypeChange} />
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="flex gap-4">
                                {
                                    backgroundColorType === 'colored' &&
                                    <label className="input input-bordered flex items-center gap-2">
                                        Color
                                        <input type="color" className="grow " value={backgroundColor} onChange={onBackgroundColorChange} />
                                    </label>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="collapse collapse-arrow border ">
                        <input type="radio" name={`my-accordion-`} onClick={() => onQuestionClick(6)} checked={question === 6} />
                        <div className="collapse-title text-xl font-medium">
                            Logo options
                        </div>
                        <div className="collapse-content">

                            <div>
                                <label className="input input-bordered flex items-center gap-2  ">
                                    Margin
                                    <input type="number" className="grow " min={0} max={100} value={LogoMargin} onChange={onLogoMarginChange} />
                                </label>
                            </div>

                            <label className="form-control w-full  mt-2">
                           
                                <input type="file" className="file-input file-input-bordered w-full " onChange={onLogoChange} />
                                
                            </label>
                        </div>
                    </div>

                    <div className="collapse collapse-arrow border">
                        <input type="radio" name={`my-accordion-`} onClick={() => onQuestionClick(1)} checked={question === 1} />
                        <div className="collapse-title text-xl font-medium">
                            Dots Options
                        </div>
                        <div className="collapse-content">
                            <label className="input  flex items-center  gap-2 mb-3  outline-none  border-none ring-0 ">


                                Style

                                <select className="select select-bordered grow focus:outline-none " value={patternType} onChange={onPatternTypeChange}>
                                    <option value="dots">Dot</option>
                                    <option value="square">Square</option>
                                    <option value="rounded">Rounded</option>
                                    <option value="extra-rounded">Extra Rounded</option>
                                    <option value="classy">Classy</option>
                                    <option value="classy-rounded">Classy Rounded</option>
                                </select>

                            </label>

                            <div>
                                <h2>Color Type</h2>
                                <div className="flex gap-5">
                                    <div className="form-control ">
                                        <label className="label cursor-pointer flex gap-2 ">
                                            <span className="label-text">Single</span>
                                            {
                                                patternColorType === 'single' && <input type="radio" name={`patternColorType`} className="radio " value="single" checked onChange={onPatternColorTypeChange} />
                                            }
                                            {
                                                patternColorType !== 'single' && <input type="radio" name={`patternColorType`} className="radio " value="single" onChange={onPatternColorTypeChange} />
                                            }
                                        </label>
                                    </div>
                                    <div className="form-control">
                                        <label className="label cursor-pointer flex gap-2">
                                            <span className="label-text">Gradient</span>
                                            {
                                                patternColorType === 'gradient' && <input type="radio" name={`patternColorType`} className="radio " value="gradient" checked onChange={onPatternColorTypeChange} />
                                            }
                                            {
                                                patternColorType !== 'gradient' && <input type="radio" name={`patternColorType`} className="radio " value="gradient" onChange={onPatternColorTypeChange} />
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="flex gap-4">
                                <label className="input input-bordered flex items-center gap-2">
                                    Color 1
                                    <input type="color" className="grow" value={patternColor1} onChange={onPatternColor1Change} />
                                </label>
                                {
                                    patternColorType === 'gradient' &&
                                    <label className="input input-bordered flex items-center gap-2">
                                        Color 2
                                        <input type="color" className="grow" value={patternColor2} onChange={onPatternColor2Change} />
                                    </label>
                                }
                            </div>
                        </div>
                    </div>


                    <div className="collapse collapse-arrow border">
                        <input type="radio" name={`my-accordion-`} onClick={() => onQuestionClick(2)} checked={question === 2} />
                        <div className="collapse-title text-xl font-medium">
                            Outer Eye Options
                        </div>
                        <div className="collapse-content">
                            <label className="input flex items-center gap-2 mb-3 ">
                                Style
                                <select className="select select-bordered grow" value={outerEyePattern} onChange={onOuterEyeTypeChange}>
                                    <option value="dots">Dot</option>
                                    <option value="square">Square</option>
                                    <option value="extra-rounded">Extra Rounded</option>

                                </select>

                            </ label>

                            <div>
                                <h2>Color Type</h2>
                                <div className="flex gap-5">
                                    <div className="form-control">
                                        <label className="label cursor-pointer flex gap-2">
                                            <span className="label-text">Single</span>
                                            {
                                                outerEyeColorType === 'single' && <input type="radio" name={`outerEyeColorType`} className="radio " value="single" checked onChange={onOuterEyeColorTypeChange} />
                                            }
                                            {
                                                outerEyeColorType !== 'single' && <input type="radio" nname={`outerEyeColorType`} className="radio " value="single" onChange={onOuterEyeColorTypeChange} />
                                            }
                                        </label>
                                    </div>
                                    <div className="form-control">
                                        <label className="label cursor-pointer flex gap-2">
                                            <span className="label-text">Gradient</span>
                                            {
                                                outerEyeColorType === 'gradient' && <input type="radio" name={`outerEyeColorType`} className="radio " value="gradient" checked onChange={onOuterEyeColorTypeChange} />
                                            }
                                            {
                                                outerEyeColorType !== 'gradient' && <input type="radio" name={`outerEyeColorType`} className="radio " value="gradient" onChange={onOuterEyeColorTypeChange} />
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="flex gap-4">
                                <label className="input input-bordered flex items-center gap-2">
                                    Color 1
                                    <input type="color" className="grow" value={outerEyeColor1} onChange={onOuterEyeColor1Change} />
                                </label>
                                {
                                    outerEyeColorType === 'gradient' &&
                                    <label className="input input-bordered flex items-center gap-2">
                                        Color 2
                                        <input type="color" className="grow" value={outerEyeColor2} onChange={onOuterEyeColor2Change} />
                                    </label>
                                }


                            </div>
                        </div>
                    </div>


                    <div className="collapse collapse-arrow  border">
                        <input type="radio" name={`my-accordion-`} onClick={() => onQuestionClick(3)} checked={question === 3} />
                        <div className="collapse-title text-xl font-medium">
                            Inner Eye Options
                        </div>
                        <div className="collapse-content">
                            <label className="input  flex items-center gap-2 mb-">
                                Style
                                <select className="select select-bordered grow" value={innerEyePattern} onChange={onInnerEyeTypeChange}>
                                    <option value="dots">Dot</option>
                                    <option value="square">Square</option>

                                </select>

                            </ label>

                            <div>
                                <h2>Color Type</h2>
                                <div className="flex gap-5">
                                    <div className="form-control">
                                        <label className="label cursor-pointer flex gap-2">
                                            <span className="label-text">Single</span>
                                            {
                                                innerEyeColorType === 'single' && <input type="radio" name={`innerEyeColorType`} className="radio " value="single" checked onChange={onInnerEyeColorTypeChange} />
                                            }
                                            {
                                                innerEyeColorType !== 'single' && <input type="radio" name={`innerEyeColorType`} className="radio " value="single" onChange={onInnerEyeColorTypeChange} />
                                            }
                                        </label>
                                    </div>
                                    <div className="form-control">
                                        <label className="label cursor-pointer flex gap-2">
                                            <span className="label-text">Gradient</span>
                                            {
                                                innerEyeColorType === 'gradient' && <input type="radio" name={`innerEyeColorType`} className="radio " value="gradient" checked onChange={onInnerEyeColorTypeChange} />
                                            }
                                            {
                                                innerEyeColorType !== 'gradient' && <input type="radio" name={`innerEyeColorType`} className="radio " value="gradient" onChange={onInnerEyeColorTypeChange} />
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="flex gap-4">
                                <label className="input input-bordered flex items-center gap-2">
                                    Color 1
                                    <input type="color" className="grow" value={innerEyeColor1} onChange={onInnerEyeColor1Change} />
                                </label>
                                {
                                    innerEyeColorType === 'gradient' &&
                                    <label className="input input-bordered flex items-center gap-2">
                                        Color 2
                                        <input type="color" className="grow" value={innerEyeColor2} onChange={onInnerEyeColor2Change} />
                                    </label>
                                }


                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div className="lg:mt-0 mt-5 lg:w-96 ">

                <div ref={ref} className=" flex justify-center   "></div>
                <div className="">

                    <div className="mb-3 mt-8">

                        <div className="flex gap-5 justify-center">

                            <div className="form-control ">
                                <label className="label cursor-pointer flex gap-2">
                                    <span className="label-text">PNG</span>
                                    <input type="radio" name={`download-ext`} className="radio checked:bg-blue-500" checked={downloadExt === 'png'} value="png" onChange={onDownloadExtChange} />
                                </label>
                            </div>
                            <div className="form-control ">
                                <label className="label cursor-pointer flex gap-2">
                                    <span className="label-text">SVG</span>
                                    <input type="radio" name={`download-ext-`} className="radio checked:bg-red-500" checked={downloadExt === 'svg'} value="svg" onChange={onDownloadExtChange} />
                                </label>
                            </div>

                        </div>
                    </div>
                    <div className="mb-5">

                        <h4 className="text-black text-md mb-1 ">Size</h4>
                        <input type="range" min={0} max={4} className="range" step={1} value={downloadSize} onChange={onDownloadSizeChange} />
                        <div className="w-full flex justify-between text-xs px-2" >
                            <span>256px</span>
                            <span>512px</span>
                            <span>1024px</span>
                            <span>2048px</span>
                            <span>4k</span>
                        </div>
                    </div>
                    <div className="flex justify-around">

                        <button className="btn  text-white bg-indigo-400 px-6" onClick={downloadQR}>Download</button>
                    </div>
                </div>
            </div>
        </div>

    );
}


export default QrCodeDisplay;