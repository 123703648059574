function PrivicyPolicy() {
    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md mt-10">
            <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
            <p className="text-gray-600 mb-6">Effective Date: April 09, 2024</p>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Information We Collect</h2>
                <p className="text-gray-700 mb-2">When you sign up using Google OAuth, we collect the following information:</p>
                <ul className="list-disc list-inside text-gray-700">
                    <li>Email address</li>
                    <li>First name</li>
                    <li>Last name</li>
                    <li>Profile picture</li>
                </ul>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">How We Use Your Data</h2>
                <p className="text-gray-700">We use your data solely for login purposes to prevent spam and ensure the security of our service. Your email address, first name, last name, and profile picture are stored securely and are never shared for any commercial purposes.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Disclosure of Information to Third Parties</h2>
                <p className="text-gray-700">We do not share your personal data with any third parties. However, we may share aggregated data, such as the total number of users, for analytical purposes. Such information does not identify you individually.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">How We Protect Your Personal Information</h2>
                <p className="text-gray-700">We take all known measures to safeguard your data and prevent unauthorized access. Our security practices include, but are not limited to:</p>
                <ul className="list-disc list-inside text-gray-700">
                    <li>Encryption of sensitive data</li>
                    <li>Secure storage solutions</li>
                    <li>Regular security audits and updates</li>
                </ul>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">User Control and Data Deletion</h2>
                <p className="text-gray-700">You have the right to delete your QR code data at any time. If you choose to delete your data, it will be permanently removed from our servers.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Changes to This Privacy Policy</h2>
                <p className="text-gray-700">We retain the discretion to amend or modify this Privacy Policy from time to time. If we make material changes to the way we collect, use, or disclose Personally Identifiable Information, we will notify you by posting a clear and prominent announcement on our website.</p>
            </div>
        </div>
    );
}

export default PrivicyPolicy;